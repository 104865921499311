.full-page{
    width: 100vw;
    overflow-x: hidden;
    min-height: 100vh;
    height: fit-content;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; 
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
    display: none;
}

.full-height{
    height: 100%;
}

.full-screen-height {
    height: 100vh;
}

.full-width{
    width: 100%;
}

.full-screen-width {
    width: 100vw;
}

.poppins{
    font-family: 'Poppins', sans-serif;
}

.font-sm {
    font-size: small;
}

a {
    text-decoration: none;
    color: inherit;
}

a:hover {
    color: inherit;
}


.transition-all {
    transition: all 1s ease-in-out;
}
.navbar-style{
    background-color: transparent;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
}

.navbar-nav .nav-item .nav-link {
    transition: all 0.5s ease;
    color: inherit;
    opacity: 0.75;
}

.navbar-nav .nav-item .nav-link:focus {
    /* color: rgb(16, 16, 16) !important; */
    opacity: 1 !important;
    outline: 0 !important;
    box-shadow: none;
    transform: scale(1.2);
}

.navbar-nav .nav-item .nav-link:hover {
    /* color: rgb(16, 16, 16) !important; */
    opacity: 1 !important;
}

.navbar-toggler:focus {
    outline: 0 !important;
    box-shadow: none;
}

.bg-light-orange {
    background-color: rgba(255, 237, 200, 0.373);
}

.bg-transblack{
    background-color: rgba(0, 0, 0, 0.57);
}

.bg-trans-grey{
    /* background-color: rgb(240, 236, 235); */
    background: linear-gradient(187deg, rgb(240, 236, 235),rgb(233, 234, 237), white);
}

.text-grey{
    color: rgb(179, 179, 179);
}

.text-green {
    color: rgba(0, 255, 17);
}

.bottom-white-transition-border {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 30px;
    background-image: linear-gradient(0deg,rgb(255, 255, 255), rgba(255, 255, 255, 0));
}


.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }

.project-circle-wrapper {
    position: relative;
    left: 0px;
    top: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-circle {
    position: relative;
    width: 25vw;
    height: 25vw;
    padding: 13vw;
    border-radius: 13vw;
}

.project-1pic-wrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.project-2pic-wrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.project-2pic-wrapper-inner {
    position: relative;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.project-pic1 {
    position: relative;
    top: 20px;
}
.project-pic2 {
    position: relative;
    top: -20px;
    align-self: flex-end;
}

.project-pic1-right {
    position: relative;
    align-self: flex-end;
    top: 20px;
}
.project-pic2-right {
    position: relative;
    top: -20px;
}


@media screen and (max-width: 1200px){
    .project-circle {
        position: relative;
        min-width: 40vw;
        min-height: 40vw;
        padding: 20vw;
        border-radius: 20vw;
    }
    
}

@media screen and (max-width: 768px){
    .header-style{
        position: absolute;
        width: 100vw;
        height: 100vh;
    }

    .project-circle {
        position: relative;
        min-width: 60vw;
        min-height: 60vw;
        padding: 30vw;
        border-radius: 30vw;
    }
}



/* ora project page */
.ora-bg {
    position: absolute;
    top: 0px;
    left: 0px;
    filter: blur(2px) brightness(50%);
}

.image-roll-cover {
    width: 100%;
    min-height: 300px;
    background-color: rgba(0, 255, 17, 0.188);
    backdrop-filter: blur(3px);
    position: absolute;
    top: -100%;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
    padding: 25px;
    transition: all 0.5s ease-out;
}

.image-roll-wrapper {
    position: relative;
    cursor: pointer;
    overflow: hidden;
}
.image-roll-wrapper:hover .image-roll-cover {
    top: 0px;
}

/* Gallery */
.gallery-image-wrapper {
    border-radius: 15px;
    overflow: hidden;
    margin: 5px;
    height: fit-content;
}

.gallery-image-text {
    padding: 15px;
}